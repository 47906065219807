:root {

  --coolGrey1: rgba(249, 250, 251, 1);
  --coolGrey2: rgba(243, 244, 246, 1);
  --coolGrey3: rgba(229, 231, 235, 1);
  --coolGrey4: rgba(209, 213, 219, 1);
  --coolGrey5: rgba(156, 163, 175, 1);
  --coolGrey6: rgba(107, 114, 128, 1);
  --coolGrey7: rgba(75, 85, 99, 1);
  --coolGrey8: rgba(55, 65, 81, 1);
  --coolGrey9: rgba(31, 41, 55, 1);
  --coolGrey10: rgba(17, 24, 39, 1);

  --blue1: rgba(239, 246, 255, 1);
  --blue2: rgba(219, 234, 254, 1);
  --blue3: rgba(191, 219, 254, 1);
  --blue4: rgba(147, 197, 253, 1);
  --blue5: rgba(96, 165, 250, 1);
  --blue6: rgba(59, 130, 246, 1);
  --blue7: rgba(37, 99, 235, 1);
  --blue8: rgba(29, 78, 216, 1);
  --blue9: rgba(30, 64, 175, 1);
  --blue10: rgba(30, 58, 138, 1);

  --teal1: rgba(240, 253, 250, 1);
  --teal2: rgba(204, 251, 241, 1);
  --teal3: rgba(153, 246, 228, 1);
  --teal4: rgba(94, 234, 212, 1);
  --teal5: rgba(45, 212, 191, 1);
  --teal6: rgba(20, 184, 166, 1);
  --teal7: rgba(13, 148, 136, 1);
  --teal8: rgba(15, 118, 110, 1);
  --teal9: rgba(17, 94, 89, 1);
  --teal10: rgba(19, 78, 74, 1);

  --orange1: rgba(255, 247, 237, 1);
  --orange2: rgba(255, 237, 213, 1);
  --orange3: rgba(254, 215, 170, 1);
  --orange4: rgba(253, 186, 116, 1);
  --orange5: rgba(251, 146, 60, 1);
  --orange6: rgba(249, 115, 22, 1);
  --orange7: rgba(234, 88, 12, 1);
  --orange8: rgba(194, 65, 12, 1);
  --orange9: rgba(154, 52, 18, 1);
  --orange10: rgba(124, 45, 18, 1);

  --pink1: rgba(253, 242, 248, 1);
  --pink2: rgba(252, 231, 243, 1);
  --pink3: rgba(251, 207, 232, 1);
  --pink4: rgba(249, 168, 212, 1);
  --pink5: rgba(244, 114, 182, 1);
  --pink6: rgba(236, 72, 153, 1);
  --pink7: rgba(219, 39, 119, 1);
  --pink8: rgba(190, 24, 93, 1);
  --pink9: rgba(157, 23, 77, 1);
  --pink10: rgba(131, 24, 67, 1);

  --yellow1: rgba(255, 251, 235, 1);
  --yellow2: rgba(254, 243, 199, 1);
  --yellow3: rgba(253, 230, 138, 1);
  --yellow4: rgba(252, 211, 77, 1);
  --yellow5: rgba(251, 191, 36, 1);
  --yellow6: rgba(245, 158, 11, 1);
  --yellow7: rgba(217, 119, 6, 1);
  --yellow8: rgba(180, 83, 9, 1);
  --yellow9: rgba(146, 64, 14, 1);
  --yellow10: rgba(120, 53, 15, 1);

  --green6: rgba(16, 185, 129, 1);
  --green7: rgba(5, 150, 105, 1);
  --red7: rgba(220, 38, 38, 1);

}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
